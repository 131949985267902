<template>
  <div class="hold-transition">
    <div class="row">
      <div class="col-md-12">
        <table
          class="
            table table-bordered table-striped table-hover table-sm
            text-sm
          "
        >
          <thead class="bg-gray text-center">
            <tr>
              <th style="width: 10%">Tipo Servicio</th>
              <th>Vehiculo/Equipo/Complementarios</th>
              <th>Día/Viaje</th>
              <th>Cantidad Vehículos a Solicitar</th>
              <th>Tiempo Requerido del Servicio (Dias / Cant)</th>
              <th>Transporta Material</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr>
              <td class="text-center">
                {{ dato.tipoServicio }}
              </td>
              <td class="text-center">
                <div v-if="dato.tipo_servicio == 1">
                  {{ dato.tipoVh }}
                  {{ dato.tipoRemolque ? " - " + dato.tipoRemolque : "" }}
                </div>
                <div v-else-if="dato.tipo_servicio == 2">
                  {{ dato.tipoEquipo }}
                </div>
                <div v-else-if="dato.tipo_servicio == 3">
                  {{ dato.tipoComplemen }}
                </div>
              </td>
              <td class="text-center">
                <div v-if="dato.tipo_cantidad == 1">
                  <span class="badge bg-lime">Días</span>
                </div>
                <div v-else>
                  <span class="badge bg-navy">Viajes</span>
                </div>
              </td>
              <td class="text-center">
                {{ dato.cantidad_vehiculos }}
              </td>
              <td class="text-center">
                {{ dato.cantidad }}
              </td>
              <td class="text-center">
                <div v-if="dato.material_inventarios == 1">
                  <span class="badge badge-success">Si</span>
                </div>
                <div v-else>
                  <span class="badge badge-danger">No</span>
                </div>
              </td>
              <td class="text-center">
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  data-toggle="modal"
                  data-target="#modal-form-tipo-servicio"
                  style="cursor: pointer"
                  @click="$parent.abrirModal('Editar', dato.id)"
                >
                  <i class="fas fa-edit"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "CsSolicitudesNacionalesTipoVehiculo",
  components: {},
  props: {
    indice: Number, // Definir la prop "indice"
    dato: Object, // Definir la prop "dato" u otro tipo de dato según corresponda
  },

  data() {
    return {
      tipoServSolicitudes: [],
    };
  },
  validations: {},
  methods: {},

  async mounted() {},
};
</script>
